<template>
    <div>
        <div class="border-bottom-second">
            <p class="text-second-d">{{ date }}</p>
            <h3 class="text-main border-bottom-second p-pb-4">{{ title }}</h3>
            <p v-html="content" class="p-pt-2"></p>
        </div>
        <custom-button class="p-mt-3 p-mt-md-4 p-mt-lg-6" width="160px" text="BACK" bg-color="white" border-color="#4878b0" @click="router.push('/news')"></custom-button>
    </div>
</template>

<script>
    import { ref , onMounted } from "vue";
    import { useRouter, useRoute } from 'vue-router';
    import { formatDate } from "@/utils/date";
    import { apiExecutor } from "@/api";
    import CustomButton from "@/components/common/CustomButton";

    export default {
        name: 'News',
        components: { CustomButton },
        setup() {
            const route = useRoute();
            const router = useRouter();
            let date = ref("");
            let title = ref("");
            let content = ref("");

            onMounted(async () => {
                const data = await apiExecutor.getNews(route.params.id);
                title.value = data[0].title;
                content.value = data[0].content;
                date.value = formatDate(data[0].date);
            });

            return { date, title, content, router };
        }
    }
</script>

<style scoped lang="scss">
    h3 {
        font-size: 24px;
    }

    p {
        line-height: 28.8px;
        letter-spacing: 1.6px;
    }
</style>